import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import MainContent from './MainContent';

// TO DO: Replace 'http://localhost:8080' with the URL of your deployed backend
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const EvaluationPage = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [categories, setCategories] = useState({});
  const [evaluateAllTrigger, setEvaluateAllTrigger] = useState(0);
  const [shouldEvaluateMissing, setShouldEvaluateMissing] = useState(false);
  const [hasExistingResults, setHasExistingResults] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [evaluationResults, setEvaluationResults] = useState({});
  const [evaluationStatus, setEvaluationStatus] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/evaluation/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();

    // Load the uploaded file from localStorage if it exists
    const storedFileInfo = localStorage.getItem('uploadedFileInfo');
    if (storedFileInfo) {
      const fileInfo = JSON.parse(storedFileInfo);
      // Retrieve the file from IndexedDB
      getFileFromIndexedDB(fileInfo.name).then(file => {
        if (file) {
          setUploadedFile(file);
        }
      });
    }

    // Load evaluation results from sessionStorage
    const storedResults = sessionStorage.getItem('evaluationResults');
    if (storedResults) {
      setEvaluationResults(JSON.parse(storedResults));
      setHasExistingResults(true);
    }

    // Load evaluation status from sessionStorage
    const storedStatus = sessionStorage.getItem('evaluationStatus');
    if (storedStatus) {
      setEvaluationStatus(JSON.parse(storedStatus));
    }
  }, []);

  useEffect(() => {
    // Save evaluation results to sessionStorage whenever they change
    sessionStorage.setItem('evaluationResults', JSON.stringify(evaluationResults));
  }, [evaluationResults]);

  useEffect(() => {
    // Save evaluation status to sessionStorage whenever it changes
    sessionStorage.setItem('evaluationStatus', JSON.stringify(evaluationStatus));
  }, [evaluationStatus]);

  useEffect(() => {
    if (evaluateAllTrigger > 0) {
      // Reset the trigger after it has been processed
      setTimeout(() => setEvaluateAllTrigger(0), 0);
    }
  }, [evaluateAllTrigger]);

  useEffect(() => {
    if (shouldEvaluateMissing) {
      // Reset the trigger after it has been processed
      setTimeout(() => setShouldEvaluateMissing(false), 0);
    }
  }, [shouldEvaluateMissing]);

  const handleFileUploaded = (file) => {
    setUploadedFile(file);
    setEvaluateAllTrigger(0);
    setShouldEvaluateMissing(false);
    
    // Save file info to localStorage
    localStorage.setItem('uploadedFileInfo', JSON.stringify({
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: file.lastModified
    }));

    // Save file to IndexedDB
    saveFileToIndexedDB(file);
  };

  const handleEvaluate = () => {
    setEvaluateAllTrigger(prev => prev + 1);
  };

  const handleEvaluateMissing = () => {
    setShouldEvaluateMissing(true);
  };

  const handleDeleteAll = () => {
    // Clear all state
    setUploadedFile(null);
    setHasExistingResults(false);
    setEvaluationResults({});
    setEvaluationStatus({});
    setShouldEvaluateMissing(false);
    setEvaluateAllTrigger(0);

    // Clear sessionStorage
    sessionStorage.removeItem('evaluationResults');
    sessionStorage.removeItem('evaluationStatus');

    // Clear localStorage
    localStorage.removeItem('uploadedFileInfo');

    // Clear all items from sessionStorage that start with a genre-category key
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes('-results')) {
        sessionStorage.removeItem(key);
      }
    });

    // Delete file from IndexedDB
    deleteFileFromIndexedDB();

    // Refresh the component
    setRefreshKey(prevKey => prevKey + 1);

    console.log('All data has been deleted and states reset.');
  };

  const updateEvaluationResults = (genre, category, results) => {
    setEvaluationResults(prev => {
      const updatedResults = {
        ...prev,
        [genre]: {
          ...(prev[genre] || {}),
          [category]: results
        }
      };
      return updatedResults;
    });

    setEvaluationStatus(prev => ({
      ...prev,
      [`${genre}-${category}`]: results.length > 0 ? 
        (results[0].message === "No errors found for this category." ? 'no_errors' : 'completed') 
        : 'not_started'
    }));

    setHasExistingResults(true);
  };

  const getFileFromIndexedDB = (fileName) => {
    return new Promise((resolve, reject) => {
      const dbName = 'FileStorage';
      const storeName = 'files';
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore(storeName);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);
        const getRequest = store.get(fileName);

        getRequest.onsuccess = () => {
          resolve(getRequest.result);
        };

        getRequest.onerror = () => {
          reject(getRequest.error);
        };
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  };

  const saveFileToIndexedDB = (file) => {
    const dbName = 'FileStorage';
    const storeName = 'files';
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(storeName);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      store.put(file, file.name, file.file_id);
    };
  };

  const deleteFileFromIndexedDB = () => {
    const dbName = 'FileStorage';
    const storeName = 'files';
    const request = indexedDB.open(dbName, 1);

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const clearRequest = store.clear();

      clearRequest.onsuccess = () => {
        console.log('IndexedDB storage cleared successfully.');
      };

      clearRequest.onerror = (error) => {
        console.error('Error clearing IndexedDB storage:', error);
      };
    };

    request.onerror = (error) => {
      console.error('Error opening IndexedDB:', error);
    };
  };

  return (
    <div className="flex h-full">
      <Sidebar 
        onFileUploaded={handleFileUploaded}
        onEvaluate={handleEvaluate}
        onEvaluateMissing={handleEvaluateMissing}
        isFileUploaded={!!uploadedFile}
        hasExistingResults={hasExistingResults}
        uploadedFileName={uploadedFile ? uploadedFile.name : null}
        onDeleteAll={handleDeleteAll}
        key={refreshKey}
      />
      <div className="flex-grow flex flex-col overflow-hidden">
        <MainContent 
          uploadedFile={uploadedFile}
          categories={categories}
          evaluateAllTrigger={evaluateAllTrigger}
          shouldEvaluateMissing={shouldEvaluateMissing}
          setHasExistingResults={setHasExistingResults}
          onDeleteFile={deleteFileFromIndexedDB}
          evaluationResults={evaluationResults}
          updateEvaluationResults={updateEvaluationResults}
          evaluationStatus={evaluationStatus}
          setEvaluationStatus={setEvaluationStatus}
        />
      </div>
    </div>
  );
};

export default EvaluationPage;